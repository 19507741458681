<template>
  <b-card class="login-body mb-5 mt-8">
    <h1>Kontakta Memlist Support</h1>

    <p>E-post: support@memlist.se</p>

  </b-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapState } from 'vuex';

import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  name: 'contact',
  data() {
    return {
     
    };
  },
  
  mounted() {

  },
  methods: {
    
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
